html .crt-popup-wrapper .crt-popup .crt-popup-right .crt-popup-text .crt-popup-text-container {
	/* These are technically the same, but use both */
	overflow-wrap: normal !important;
	word-wrap: normal !important;
	-ms-word-break: normal !important;
	word-break: normal !important;
	/* Adds a hyphen where the word breaks, if supported (No Blink) */
	-ms-hyphens: auto;
	-webkit-hyphens: auto;
	hyphens: auto;
}

#product-categories a {display: block;}
#layerslider .btn {font-size: inherit;}
#layerslider .btn.btn-lg {font-size: 120%; padding: 4% 8%;}
#layerslider .ls-link {z-index: 200 !important;}

#dump-link {position: relative; display: block; cursor: default; text-decoration:  none;}
#dump-link img {opacity: 0.5}
#dump-link:hover::after {
	content: 'Coming Soon';
	position: absolute;
	top: 0;
	right: 0;
	width: 100%;
	padding: 5px 0;
	text-align: center;
	background-color: rgba(10,31,143,0.75);
	color: #fff;
}

.crt-popup-wrapper .crt-popup a:hover {color: rgba(210,210,210,1.0) !important;}

html button.crt-load-more {
  background-color: #08176b !important;
  color: #FFF!important;
  display: inline-block;
  text-align: center;
  padding: .25rem .5rem;
  text-decoration: none;
  border: 0;
  box-shadow: 0px 2px 30px transparent;
  text-transform: capitalize;}

.lg-video iframe {overflow: hidden;}

@media screen and (max-width: 575px) {
	#product-spotlight .tab-pane {background: #f8f9fa;}
	
	.tab-content>.tab-pane {
		display: block !important;
		opacity: 1 !important;
	}
	
	#product-spotlight .header {position: relative;}
	
	#product-spotlight .header h2 {
		text-shadow: 2px 2px 1px rgba(0,0,0,0.75), 2px 3px 4px rgba(0,0,0,0.35);
	}
	
	#dry-freight-container, #refrigerated-container, #platform-dump-container{margin-bottom: 1rem !important;}
}